import React from "react";
import Layout from "../componenets/layout";
// import LightBoxLight from "../componenets/LightBoxLight";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
// import 'react-tabs/style/react-tabs.css';
import "./networkmap.css";
import { Helmet } from "react-helmet";

let tabButtonList = []
tabButtonList.push({ map: "/media/map/map_stadiums.svg", alt: "Stadium Button", src: "/media/map/btn_stadiums.svg" })
tabButtonList.push({ map: "/media/map/map_fanzones.svg", alt: "Fan Zones Button", src: "/media/map/btn_fanzones.svg" })
tabButtonList.push({ map: "/media/map/map_metrolink.svg", alt: "Metrolink Button", src: "/media/map/btn_metrolink.svg", info: "https://www.qr.com.qa/metrolink", })
tabButtonList.push({ map: "/media/map/map_parkandride.svg", alt: "Park&Ride Button", src: "/media/map/btn_parkandride.svg" })

function Back() {
  return (
    <div
      className="back"
    >
      <a href="/" className="">
        <img src="/media/img/explore_network.svg" alt="explore network" className="h-20 md:h-32 lg:w-40 border-t border-r border-l border-white" />
      </a>
    </div>
  );
}

export default function IndexPage({ location }) {
  // const [image, setImage] = useState("/media/map/Stadiums.jpg");
  let title = "خريطة الشبكة - مترو وترام الريل | Network map - metro tram Qatar Rail"
  return (
    <Layout pageTitle={title} disableChat={true}>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta
          property="og:image"
          content="https://storage.googleapis.com/metrotram/qr_network.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@metrotram_qa" />
        <meta name="twitter:widgets:new-embed-design" content="on" />
        <meta name="twitter:widgets:csp" content="on" />

        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="X-UA-Compatible" content="ie=edge" />
        <meta name="description" content={title} />
      </Helmet>
      <div className="flex flex-col text-center md:p-5 md:text-4xl font-medium title-font px-4">
        <div className="grid grid-cols-2 place-content-around md:mb-4 pb-1">
          <h1 className="">
            Network map
          </h1>
          <h1 className="">
            خريطة الشبكة
          </h1>

        </div>
        <Tabs>
          <TabList className="grid grid-cols-4 gap-1 place-content-center">
            {tabButtonList.map(imgbtn =>
              <Tab className="">
                <img
                  className="object-fill rounded-md"
                  alt={imgbtn.alt}
                  src={imgbtn.src}
                />
              </Tab>
            )}
          </TabList>
          {tabButtonList.map(m =>
            <TabPanel>
              <div className="items-center shadow ring-1 ring-gray-500 rounded-lg p-1 my-2">
                <img alt={m.alt} src={m.map} className="rounded-lg" />
                {m?.info ?
                  <>
                  
                    <a href={m.info} class="h-16 w-full sm:w-96 mx-auto">
                    <img alt="info" class="h-10 md:h-16 my-2 rounded-md mx-auto border" src="/media/map/btn_info.svg" />
                    </a>
                  </>
                  : null}
              </div>
            </TabPanel>
          )}
        </Tabs>

      </div>
      <Back />
    </Layout>
  );
}

// function Map({ src }) {
//   return (
//     <LightBoxLight
//       src={src}
//       className="object-cover w-full"
//       alt="Network map"
//       title="Network map"
//     />
//   );
// }
